// home page styling
.main {
  .row {
    padding: 0;
    border: 0;
  }

  .section {
    .section-title {
      @extend .font-intel-clear-pro-bold;
      color: $theme-stone;
      font-size: 44px;
      line-height: 1;
      text-align: center;

      &.section-title-white {
        color: white;
      }
    }
  }

  .hero-section {
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;

    .bg-angled-top {
      position: absolute;
      z-index: -2;
      background-color: $theme-link-water;
      width: 100%;
      height: 54%;
      right: 0;
      bottom: -100vw * tan(28deg);
      display: block;
      transform: skewY(-28deg);
      transform-origin: 0;
    }

    .section-title {
      margin-bottom: 25px;
      text-align: center;
    }

    .hero-content {
      @extend .font-intel-clear-latin-lite;
      margin-bottom: 30px;
      text-align: center;
    }

    .home-hero-video {
      @extend .flex-center;
      border: 4px solid $theme-flame-pea;
      // background-image: url("/img/home-hero.png");
      background-image: url("https://media.giphy.com/media/PjJ1cLHqLEveXysGDB/source.gif");
      height: 200px;
      background-size: cover;
      background-position: center;
      cursor: pointer;
      margin-bottom: 30px;

      &:hover {
        .home-hero-play {
          background-color: $hover-theme-flame-pea;
        }
      }

      img {
        margin-bottom: 0;
      }
    }

    .home-hero-play {
      @extend .flex-center;
      background-color: $theme-flame-pea;
      width: 55px;
      height: 60px;
      border-radius: 4px;

      .play-icon {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 14px solid white;
        margin-left: 3px;
      }
    }
  }

  .actions {
    text-align: center;

    .sign-up {
      justify-content: flex-end;
      margin-right: 35px;
      display: inline-block;
      border-radius: 6px;

      a {
        @extend .font-intel-clear-latin-bold;
        @extend .bg-gradient-blue;
        color: white;
        padding: 12px 15px;
        height: 42px;
      }
    }

    .know-more {
      @extend .font-intel-clear-latin-bold;
      @extend .bg-gradient-blue;
      color: white;
      padding: 12px 18px;
      display: inline-block;
      line-height: 1;
      cursor: pointer;
      border: 0;
      height: 42px;
    }
  }

  .why-join-section {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: $theme-stone;

    .reasons-block {
      @extend .d-flex;
      align-items: flex-end;
      margin: 0;
    }

    .section-title {
      margin-bottom: 30px;
      text-align: center;
    }

    .reasons {
      padding-right: 0;
      padding-left: 5px;
      margin-bottom: 80px !important;
    }

    .reason {
      margin-bottom: 0;

      &:first-child {
        margin-bottom: 10px;
      }

      .reason-count,
      .reason-title {
        @extend .font-intel-clear-pro-bold;
        cursor: pointer;
        color: $theme-white-50;
      }

      .reason-count {
        font-size: 60px;
        line-height: 1;
        margin-bottom: 0;
        opacity: 0.4;
      }

      .reason-title {
        font-size: 24px;
        line-height: 1;
        margin-bottom: 0;
      }

      &.active {
        .reason-count,
        .reason-title {
          color: $theme-wattle;
        }
      }
    }

    .reasons-expand {
      padding-left: 0;
      padding-right: 0;
    }

    .reason-expand {
      background-color: white;
      position: relative;

      .reason-count-row {
        margin-left: 0;
        margin-right: 0;
      }

      .reason-count-block {
        position: absolute;
        margin-top: -80px;
        top: 0;

        .reason-count {
          @extend .font-intel-clear-pro-bold;
          color: $theme-wattle;
          font-size: 150px;
        }
      }

      .reason-content {
        padding: 15px;
      }

      .reason-image {
        width: 60px;
        margin-bottom: 10px;
      }

      .reason-title {
        @extend .font-intel-clear-pro-bold;
        font-size: 36px;
        line-height: 1;
        margin-bottom: 20px;
        color: $theme-stone;
      }

      .reason-text {
        @extend .font-intel-clear-latin-lite;
        color: $theme-blackcurrant;
        margin-bottom: 0;
        max-width: 210px;
      }
    }
  }

  .event-section {
    padding-top: 50px;
    padding-bottom: 20px;
    z-index: 0;
    background-color: white;

    .section-title {
      margin-bottom: 30px;
      line-height: 1;
    }

    .events-banner {
      max-width: 100%;
      margin-bottom: 25px;
    }

    .event {
      @extend .d-flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      &:first-child {
        margin-bottom: 35px;
      }

      .event-date {
        @extend .font-intel-clear-pro-bold;
        color: $theme-jaffa;
        margin-bottom: 7px;
        font-size: 30px;
        line-height: 1;
      }

      .event-time {
        @extend .font-intel-clear-latin-lite;
        color: $theme-blackcurrant-50;
        font-size: 14px;
        line-height: 1;
        margin-bottom: 0;
      }

      .event-details {
        width: calc(100% - 50px);
      }

      .event-title {
        @extend .font-intel-clear-pro-bold;
        font-size: 30px;
        line-height: 1;
        color: $theme-mariner;
        margin-bottom: 8px;
        text-align: left;
      }

      .event-tags {
        @extend .d-flex;
        margin-bottom: 8px;
      }

      .event-tag {
        @extend .font-intel-clear-latin-bold;
        color: $theme-mariner;
        background-color: $theme-link-water;
        margin-right: 4px;
        padding: 6px 12px;
        font-size: 10px;
        line-height: 1;
        border-radius: 4px;
      }

      .event-description {
        @extend .font-intel-clear-latin-lite;
        color: $theme-blackcurrant;
        margin-bottom: 0;
        font-size: 12px;
        line-height: 1.8;
      }
    }

    .event-actions {
      @extend .d-flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;

      .slider-arrow,
      .view-all-events {
        @extend .font-intel-clear-latin-bold;
        @extend .bg-gradient-blue;
        color: white;
        display: inline-block;
        line-height: 1;
        box-shadow: none;
        border: 0;
        height: 40px;
      }

      .slider-arrow {
        width: 35px;

        &.slider-prev {
          margin-right: 5px;
        }

        &.disabled {
          background-image: none;
          background-color: $theme-mischka;
        }
      }

      .slider-icon {
        font-weight: bold;
      }

      .view-all-events {
        margin-left: 15px;
        padding: 12px 18px;
        height: 40px;
      }
    }
  }

  .subscribe-section {
    padding-top: 30px;
    padding-bottom: 30px;
    background-image: linear-gradient(
      to right,
      $theme-link-water,
      white,
      white
    );

    .section-title {
      margin-bottom: 30px;
      font-size: 34px;
    }

    .section-content {
      @extend .font-intel-clear-latin-lite;
      margin-bottom: 30px;
      color: $theme-blackcurrant;
    }

    .subscribe-content {
      @extend .d-flex;
      flex-direction: column-reverse;
    }

    .subscribe-banner {
      max-width: 100%;
      margin-bottom: 30px;
    }

    input[type="text"] {
      @extend .font-intel-clear-latin-lite;
      color: $theme-blackcurrant;
      width: 100%;
      height: 50px;
      border: 0;
      background-color: white;
      padding-left: 10px;
      padding-right: 100px;
      font-size: 14px;
      line-height: 1;
    }

    button {
      @extend .font-intel-clear-latin-bold;
      background-color: $theme-flame-pea;
      color: white;
      margin-left: -90px;
      height: 30px;
      width: 80px;
      border: 0;
      font-size: 12px;
      -webkit-appearance: none;

      &:hover {
        background-color: $hover-theme-flame-pea;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.6;

        &:hover {
          background-color: $theme-flame-pea;
        }
      }
    }
  }
}

.preloader {
  background: -moz-linear-gradient(top, #4096ee 0%, #39ced6 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #4096ee 0%, #39ced6 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #4096ee 0%, #39ced6 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4096ee', endColorstr='#39ced6', GradientType=0);
  /* IE6-9 */
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
  text-align: center;
}

.preloader-bounce {
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
}

.preloader-bounce > span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0.3em;
  display: inline-block;
  vertical-align: middle;
  -webkit-animation: bounce 0.6s infinite alternate;
  animation: bounce 0.6s infinite alternate;
  background: #fff;
}

.preloader-bounce > span:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  background: #fff;
}

.preloader-bounce > span:nth-child(3) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  background: #fff;
}

.popup-content {
  width: 50% !important;
  height: 5% !important;
  top: 30% !important;
  left: 50% !important;
  background: #fff !important;
  padding: 0 !important;
  box-shadow: none !important;
  border: 0px !important;
  border-radius: 0px !important;
  transform: translate(-50%);

  .popup-arrow {
    display: none !important;
  }

  .ai-global-link {
    color: #00406f;

    a {
      text-decoration: underline !important;
      color: #00406f;
      cursor: pointer;
    }
  }
}

@-webkit-keyframes bounce {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    -webkit-transform: translateY(-1.2em);
    transform: translateY(-1.2em);
  }
}

@keyframes bounce {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    -webkit-transform: translateY(-1.2em);
    transform: translateY(-1.2em);
  }
}

@import "md";
@import "xl";
